import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';
import { Employee } from '../../../modules/cs/common/components/employee';

import '../../generic-page.scss';

const CSShadowPalaceF1Page: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page'} game="cs">
      <ul className="breadcrumb">
        <li>
          <Link to="/counter-side">Counter Side</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/counter-side/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Shadow Palace - Floor 1</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/counterside/categories/shadow_floor_1.png"
            alt="Shadow Palace"
          />
        </div>
        <div className="page-details">
          <h1>Shadow Palace - Floor 1</h1>
          <h2>Guide for the first floor of Shadow Palace.</h2>
        </div>
      </div>
      <div className="page-content">
        <SectionHeader title="MVP employees" />
        <div className="employee-container">
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="lyudmila" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
          <Employee slug="kestrel-xiao-lin" mode="icon" enablePopover />
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
        </div>
        <p>
          Snipers are really important here as they can simply out-range the
          evil Joo Shiyoon counter attack.
        </p>
        <div className="employee-container">
          <Employee slug="joo-shiyoon" mode="icon" enablePopover />
        </div>
        <p>Bring your own Joo Shiyoon to deal with the evil Shiyoon!</p>
        <SectionHeader title="Enemies" />
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_JOO_SHI_YOON.webp"
          alt="Shadow Enemy"
        />
        <h4>Shadow Joo Shiyoon</h4>
        <p>
          The biggest problem on Floor 1 is Joo Shiyoon because he can counter
          your skills and once he does, he one-shots your team basically. The
          best way of dealing with it is to either safely detonate it with a
          sacrifice (e.g. Kang’s car) or to just use someone who can trigger and
          tank it out before placing the rest of the team (e.g. Yumi). Still,
          similar to normal Shiyoon, he can’t counter awakened units' skills, so
          if you rely on those, you will be safe. You can also use your Shiyoon
          to counter the evil Shiyoon which is actually pretty funny.
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_HB_RUMI.webp"
          alt="Shadow Enemy"
        />
        <h4>Shadow Lumi</h4>
        <p>
          Lumi is the second unique enemy you will fight on this floor and it’s
          important to attack and hitstun her within 7 seconds after the battle
          starts. That’s the only way to stop her from summoning her car that
          will roll over your team like she did that poor elephant in her
          Counter Case. #JusticeForElephante{' '}
        </p>
        <StaticImage
          src="../../../images/counterside/shadow/AB_INVEN_ICON_NKM_SHADOW_C_JAPAN_ZENJIROU.webp"
          alt="Shadow Enemy"
        />
        <h4>Shadow Ogami Masaki</h4>
        <p>
          The third unique enemy is Ogami Masaki, but he doesn’t really do
          anything special here.
        </p>
        <h4>Ship</h4>
        <p>
          The ship that you will fight here is a modified Balisada that works
          similar to Enterprise - the skill pulls your units to the center and
          debuffs their evasion.
        </p>
        <SectionHeader title="Restrictions" />
        <ul>
          <li>Room 1 - Strikers are banned</li>
          <li>Room 2 - Defenders are banned</li>
          <li>Room 3 - Rangers are banned</li>
          <li>Room 4 - Max 25 deployment cost</li>
          <li>Room 5 - Max 25 deployment cost and Mechs are banned</li>
        </ul>
        <SectionHeader title="Important" />
        <p>
          <strong>
            Any room can be cleared with dozens, if not more, different teams
          </strong>
          . Obviously, if you have Yuna/Hilde/ASY, you can slap her into your
          team and she will carry you, but the example teams below are targeted
          toward players that don’t really have any awakened units.
        </p>
        <p>
          Also Floor one is really easy and even in Global, the majority of
          players should be able to clear it easily, don't worry about tuning
          your team for auto-battle. Floor 2 and 3 should be the place you will
          want to optimize your teams more.
        </p>
        <SectionHeader title="Room 1" />
        <p>
          For Room 1, the tactic is to counter-counter Joo Shiyoon and keep him
          CCed and if you do that, you’re basically done with the room.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="joo-shiyoon" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="assault-trooper" mode="icon" enablePopover />
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 2" />
        <p>
          Here speed and CC matter the most. You want to keep Lumi under CC all
          the time, so she can’t summon the damn monster truck that can wipe
          your team. Still, with strong enough setup you can ignore her
          mechanic.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="sigma" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
          <Employee slug="joo-shiyoon" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 3" />
        <p>
          Probably the easiest room on this floor because you only fight Ogami
          here. Just overwhelm him and you’ve basically won.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="lin-xien" mode="icon" enablePopover />
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="xiao-lin" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="cindy-looper" mode="icon" enablePopover />
          <Employee slug="irie-alford" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 4" />
        <p>
          This time you fight Lumi and Masaki at the same time, but he’s not a
          big threat so ignore him and focus on Lumi. Still, this is the first
          stage with a 25 deployment cost limit, so you have to carefully pick
          and choose who you want to bring.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="lin-xien" mode="icon" enablePopover />
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="sylvia-lena-cooper" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
        </div>
        <SectionHeader title="Room 5" />
        <p>
          The boss room where you fight the whole trio. There’s nothing really
          different from other rooms here besides that.
        </p>
        <h4>Example team (order matters):</h4>
        <div className="employee-container">
          <Employee slug="lin-xien" mode="icon" enablePopover />
          <Employee slug="edel-meitner" mode="icon" enablePopover />
          <Employee slug="joo-shiyoon" mode="icon" enablePopover />
          <Employee slug="administration-shieldman" mode="icon" enablePopover />
        </div>
        <br />
        <div className="employee-container">
          <Employee slug="kyle-wong" mode="icon" enablePopover />
          <Employee slug="evelyn-keller" mode="icon" enablePopover />
          <Employee slug="nanahara-chifuyu" mode="icon" enablePopover />
          <Employee slug="administration-rifleman" mode="icon" enablePopover />
        </div>
      </div>
    </DashboardLayout>
  );
};

export default CSShadowPalaceF1Page;

export const Head: React.FC = () => (
  <Seo
    title="Shadow Palace 1 guide | Counter Side | Prydwen Institute"
    description="Guide for the first floor of Shadow Palace."
  />
);
